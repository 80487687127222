import './CallScreen.css';
import { useEffect, useRef, useState } from 'react';
import { CircularProgress } from '@mui/material';


const getAvatarMessageWordCount = (message) => {
  const avatarMessageIndex = message.lastIndexOf("</b>");
  if (avatarMessageIndex !== -1) {
    let avatarMessage = message.substring(avatarMessageIndex + 4);
    return avatarMessage.split(/\s+/).filter(word => word.trim().length > 0).length;
  }
  return 0;
}

const CallScreen = ({ url, onDisconnect, scenarioData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentMessage, setCurrentMessage] = useState('');
  const canvasRef = useRef(null);
  const videoRef = useRef(null);
  const peerConnection = useRef(null);
  const dataChannel = useRef(null);
  const unityInstance = useRef(null);
  
  const initializeUnity = async () => {
    try {

      await new Promise(resolve => setTimeout(resolve, 1000));

      if (!window.createUnityInstance) {
        throw new Error('Unity loader error');
      }

      const instance = await window.createUnityInstance(canvasRef.current, {
        dataUrl: "/avatar/GuruNowBuild.data.unityweb",
        frameworkUrl: "/avatar/GuruNowBuild.framework.js.unityweb",
        codeUrl: "/avatar/GuruNowBuild.wasm.unityweb",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "GuruNow",
        productName: "GuruNow AI",
        productVersion: "0.1",
        matchWebGLToCanvasSize: false,
      });

      unityInstance.current = instance;
      window.unityInstance = instance;

      unityInstance.current.SendMessage("AvatarManager", "InstantiateAvatar", String(scenarioData.avatar_persona.avatar_model));

    } catch (error) {
      console.error('Failed to initialize Unity:', error);
      throw error;
    }
  };

  const triggerSpeechAnimation = (message) => {
    if (unityInstance.current) {
      unityInstance.current.SendMessage("AvatarManager", "OnMessageReceived", message);
    }
  };

  const negotiate = async () => {
    const pc = peerConnection.current;
    if (!pc) {
      throw new Error('Peer connection not initialized');
    }

    try {
      const offer = await pc.createOffer();
      await pc.setLocalDescription(offer);

      await new Promise((resolve) => {
        if (pc.iceGatheringState === 'complete') {
          resolve();
        } else {
          const checkState = () => {
            if (pc.iceGatheringState === 'complete') {
              pc.removeEventListener('icegatheringstatechange', checkState);
              resolve();
            }
          }
          pc.addEventListener('icegatheringstatechange', checkState);
        }
      });

      const urlParams = new URL(url);
      const response = await fetch(`${urlParams.origin}/offer`, {
        body: JSON.stringify({
          sdp: pc.localDescription.sdp,
          type: pc.localDescription.type,
          simulation_id: urlParams?.searchParams?.get('simulation_id')
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      });

      const answer = await response.json();
      if (pc.signalingState !== 'closed') {
        await pc.setRemoteDescription(answer);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const initializeCall = async () => {
    if (peerConnection.current) {
      cleanup();
      await new Promise(resolve => setTimeout(resolve, 500));
    }

    try {
      const pc = createPeerConnection();
      peerConnection.current = pc;

      const dc = pc.createDataChannel('chat', { ordered: true });
      dataChannel.current = dc;
      setupDataChannelHandlers();

      const stream = await navigator.mediaDevices.getUserMedia({
        video: false,
        audio: true
      });

      if (pc.signalingState !== 'closed') {
        stream.getTracks().forEach((track) => {
          pc.addTrack(track, stream);
        });
      }

      if (pc.signalingState !== 'closed') {
        await negotiate();
      }

      await initializeUnity();
      setIsLoading(false);

      try {
        const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        if (videoRef.current) {
          videoRef.current.srcObject = videoStream;
        }
      } catch (err) {
        console.error('Could not acquire video:', err);
      }
    } catch (error) {
      console.error('Failed to initialize call:', error);
      cleanup();
      throw error;
    }
  };

  useEffect(() => {
    let mounted = true;

    const init = async () => {
      try {
        await initializeCall();
      } catch (error) {
        if (mounted) {
          console.error('Failed to initialize:', error);
        }
      }
    };

    init();

    return () => {
      mounted = false;
      cleanup();
    };
  }, [url]);

  const createPeerConnection = () => {
    const config = {
      sdpSemantics: 'unified-plan'
    };

    const pc = new RTCPeerConnection(config);

    pc.addEventListener('icegatheringstatechange', () => {
      console.log('ICE gathering state:', pc.iceGatheringState);
    });

    pc.addEventListener('iceconnectionstatechange', () => {
      console.log('ICE connection state:', pc.iceConnectionState);
    });

    pc.addEventListener('signalingstatechange', () => {
      console.log('Signaling state:', pc.signalingState);
    });

    pc.addEventListener('track', (evt) => {
      if (evt.track.kind === 'video') {
        if (videoRef.current) {
          videoRef.current.srcObject = evt.streams[0];
        }
      } else if (evt.track.kind === 'audio') {
        let audioElement = document.getElementById('audio');
        if (!audioElement) {
          audioElement = document.createElement('audio');
          audioElement.id = 'audio';
          audioElement.autoplay = true;
          document.body.appendChild(audioElement);
        }
        audioElement.srcObject = evt.streams[0];
        
        const audioContext = new AudioContext();
        const mediaStream = new MediaStream([evt.track]); // Create a stream from the track
        const source = audioContext.createMediaStreamSource(mediaStream);
        const analyser = audioContext.createAnalyser();

        source.connect(analyser);

        // Function to detect audio activity
        const dataArray = new Uint8Array(analyser.frequencyBinCount);
        const words = ["a", "aa", "aaa", "o", "oo", "ooo"];
        var spoken = false;

        function detectAudio() {
          analyser.getByteFrequencyData(dataArray);

          // Check if there's any non-zero audio data
          const hasAudio = dataArray.some((value) => value > 0);

          if (hasAudio && !spoken) {
            // Pick a random utterance to pass to the avatar's lipsync engine
            triggerSpeechAnimation(words[Math.floor(Math.random()*words.length)]);
            spoken = true;
          } else {
            spoken = false;
          }

          requestAnimationFrame(detectAudio);
        }

        // Start monitoring the audio data
        detectAudio();
      }
      
    });

    return pc;
  };

  const setupDataChannelHandlers = () => {
    if (!dataChannel.current) return;

    let time_start = new Date().getTime();
    let dcInterval = null;

    const current_stamp = () => {
      return new Date().getTime() - time_start;
    };

    dataChannel.current.addEventListener('open', () => {
      console.log('Data channel open');
      dcInterval = setInterval(() => {
        if (dataChannel.current?.readyState === 'open') {
          const message = 'poll ' + current_stamp();
          dataChannel.current.send(message);
        }
      }, 1000);
    });

    let currentAvatarMessage = "";
    dataChannel.current.addEventListener('message', (evt) => {
      let displayMessage = evt.data;

      if (!currentAvatarMessage.includes("Video call connected")) {
        setCurrentMessage(displayMessage);
        currentAvatarMessage = displayMessage;
      }

      if (evt.data.includes('END_OF_DISCUSSION')) {
        displayMessage = evt.data.replace('END_OF_DISCUSSION', '');
        setCurrentMessage(displayMessage);
        setTimeout(() => {
          handleDisconnect();
        }, 7000);
        return;
      } else if (displayMessage.length > currentAvatarMessage.length) {
        const avatarMessageIndex = displayMessage.lastIndexOf("</b>");
        let avatarMessage = displayMessage.substring(avatarMessageIndex + 4);
        //triggerSpeechAnimation(avatarMessage);
      }
      setCurrentMessage(displayMessage);
      currentAvatarMessage = displayMessage;
    });

    dataChannel.current.addEventListener('close', () => {
      console.log("Data channel closed");
      if (dcInterval) {
        clearInterval(dcInterval);
      }
    });
  };

  const handleDisconnect = () => {
    cleanup();
    onDisconnect();
  };

  const cleanup = () => {
    try {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        stream.getTracks().forEach(track => {
          track.stop();
        });
        videoRef.current.srcObject = null;
      }

      if (dataChannel.current) {
        dataChannel.current.close();
      }

      if (peerConnection.current) {
        peerConnection.current.getSenders().forEach((sender) => {
          if (sender.track) {
            sender.track.stop();
          }
        });
        peerConnection.current.close();
      }
    } catch (err) {
      console.error('Error during cleanup:', err);
    } finally {
      dataChannel.current = null;
      peerConnection.current = null;

      if (unityInstance.current) {
        unityInstance.current.Quit();
        unityInstance.current = null;
      }
    }
  };

  return (
    <div className="container">
      <div className="canvas-holder">
        <div className={`loading-indicator ${isLoading ? 'visible' : ''}`}>
          <CircularProgress /><br /><br />
          Connecting video call, please wait...
        </div>
        <canvas
          ref={canvasRef}
          id="unity-canvas"
          width={960}
          height={600}
          tabIndex={-1}
        />
        <video
          ref={videoRef}
          id="video"
          autoPlay
          playsInline
          muted
        />
      </div>

      <div className="data-channel" dangerouslySetInnerHTML={{ __html: currentMessage }} />
      <button onClick={handleDisconnect}>Disconnect</button>
    </div>
  );
};

export default CallScreen;
